.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $secondary;
  z-index: 100;
  overflow: hidden;
  opacity: 0;
  transform: translateX(-100%);
  padding: 64px;
  @include media("<=desktop") {
    padding: 40px;
  }
  @include media("<=tablet") {
    padding: 32px;
  }
  @include media("<=phone") {
    padding: 24px;
  }
  .grids__container {
    .row {
      .col {
        opacity: 50%;
      }
    }
  }
}
.overlay.open {
  animation: slideInFromLeft 0.6s forwards
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.overlay.close {
  animation: slideOutToTop 0.6s forwards ease-in-out;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.nav__button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 20;
  cursor: pointer;
  .lottie-container {
    &:hover {
      border-color: $primary;
      .icon {
        &:hover {
          svg path {
            transform: translateY(-60px);
            @include media("<=phone") {
              transform: none;
            }
          }
        }
      }
    }
  }
}

/* Menu Styles */
.overlay__menu {
  display: flex;
  // flex-direction: column;
  height: 100%;
  align-items: flex-end;
  gap: 4rem;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  @include media("<=tablet") {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 3rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li {
      display: flex;
      gap: 0.5rem;
      span {
        font-size: clamp(1.5em, 5vw, 2.2em);
        letter-spacing: -8%;
        font-weight: 400;
        color: $primary;
      }
    }
    a {
      color: $primary;
      font-size: clamp(2.5em, 5vw, 5em);
      letter-spacing: -8%;
      font-weight: 500;
      &:hover {
        letter-spacing: normal;
        transition: letter-spacing 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }
  }
  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 180px;
    background-color: $primary;
    padding: 10px;
    @include media("<=desktop") {
      width: 500px;
    }
    @include media("<=tablet") {
      width: 100%;
    }
    @include media("<=phone") {
      width: 100%;
    }
  }
  .data__input {
    width: 100%;
    padding-bottom: 1rem;
    input {
      width: 100%;
      height: 64px;
      border-bottom: 2px solid $primary;
      &::placeholder {
        font-size: clamp(1em, 1.5vw, 1.5em);
        font-family: "Montserrat", sans-serif;
        color: $primary;
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .input input {
    border-radius: 180px;
    padding: 10px 10px 10px 22px;
    @include media("<=phone") {
      padding: 6px 6px 6px 10px;
    }
    width: 100%;
    height: 100%;
    background-color: $primary;
  }

  .input input,
  .input input::placeholder {
    font-size: clamp(1em, 1.5vw, 1.5em);
    font-family: "Montserrat", sans-serif;
    color: $black;
    opacity: 70%;
  }

  .input:hover .input-arrow .lottie-container {
    border-color: $black;
    .icon {
      svg {
        transform: rotate(-45deg) translateX(56px);
        transition: all 0.4s ease-in-out;
      }
    }
    .lottie {
      display: block;
    }
  }
}
