.detailed-project {
  padding: 40px;
  .back__button{
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
  .project-header {
    text-align: center;
    margin-bottom: 60px;
    h1 {
      font-size: 4rem;
      letter-spacing: -8%;
      color: $secondary;
      margin-bottom: 10px;
    }

    p {
      font-size: 2rem;
      font-weight: 320;
      margin-bottom: 20px;
    }

    .project-meta {
      display: flex;
      justify-content: center;
      gap: 30px;
      font-size: 1.2rem;

      div {
        text-align: left;

        strong {
          font-weight: 700;
          margin-right: 5px;
        }
      }
    }
  }

  section {
    margin-bottom: 80px;
    h2 {
      font-size: 2rem;
      color: $secondary;
      margin-bottom: 20px;
      padding-bottom: 5px;
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      margin-top: 20px;
      border-radius: 8px;
    }

    &.overview {
      .overview-details {
        margin-top: 20px;
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        div {
          flex: 1;
          min-width: 150px;

          strong {
            font-weight: bold;
          }
        }
      }
    }
  }
  .additional-images {
    .images-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
