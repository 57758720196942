.service-section {
  background-color: $secondary;
  position: relative;
  overflow: hidden;
  .service-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    z-index: 10;
    .title-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      z-index: 10;
      .service-title {
        font-size: clamp(4em, 16vw, 16em);
        letter-spacing: -8%;
        font-weight: 800;
        color: $primary;
        line-height: 80%;
      }
      .tags-container {
        display: flex;
        position: relative;

        @include media("<=tablet") {
          @keyframes marquee {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(-500%);
            }
          }
          animation: marquee 24s linear infinite;
        }
        .service-tags {
          position: relative;
          padding: 0 2rem;
          text-align: center;
          mix-blend-mode: exclusion;
          @include media("<=phone") {
            padding: 0 1.5rem;
          }
          span {
            position: relative;
            z-index: 10;
            font-size: clamp(3.8em, 8vw, 8em);
            font-weight: 200;
            color: $black;
          }
          .img-wrapper {
            position: absolute;
            opacity: 0;
            top: 0%;
            left: 80%;
            transform: translate(-50%, -50%) scale(0.5);
            transition: all 250ms;
            width: 200px;
            height: 300px;
            overflow: hidden;
            img {
              position: absolute;
              opacity: 0;
              top: 50%;
              left: 50%;
              transform: scale(2.5);
              transition: transform 200ms cubic-bezier(0.075, 0.82, 0.165, 1),
                opacity 250ms;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          &:hover .img-wrapper {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
          &:hover .img-wrapper img {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.5);
          }
        }
      }
    }
    .service-arrows {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 10;
      @include media("<=400px") {
        top: 50%;
        transform: translateY(-50%);
      }
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateY(-60px);
                @include media("<=phone") {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .grids__container{
    .row{
        .col{
            opacity: 50%;
        }
    }
  }
}
