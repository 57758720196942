header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  .message {
    position: absolute;
    top: 0;
    left: 0;
    color: $secondary;
    span {
      color: $black;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    .logo {
      a {
        font-size: clamp(1.5em, 2vw, 2.5em);
        font-weight: 320;
        letter-spacing: -8%;
        transition: letter-spacing 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        &:hover {
          color: $secondary;
          letter-spacing: 2%;
        }
      }
    }
    .navButton {
      .lottie-container {
        &:hover {
          border-color: $black;
        }
      }
    }
  }
}
