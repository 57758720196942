.section {
  .header__footer {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include media("<=tablet") {
      padding-bottom: 70px;
      flex-direction: column;
      gap: 0.8rem;
    }
    .footer__left {
      width: 60%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include media("<=tablet") {
        width: 100%;
      }
      .left__tag {
        font-size: clamp(1em, 2vw, 1.5em);
        letter-spacing: -2%;
        text-align: right;
        @include media("<=tablet") {
          order: 1;
        }
      }
      .image__gallery {
        width: 50%;
        overflow: hidden;
        .scroller {
          display: flex;
          align-items: center;
          animation: scroll-left 8s linear infinite;
          &:hover {
            animation-play-state: paused;
          }
          img {
            width: 64px;
            height: 64px;
            border-radius: 4px;
            margin-left: 0.5rem;
            @include media("<=tablet") {
              width: 56px;
              height: 56px;
            }
          }
        }
      }
    }
    .footer__right {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media("<=tablet") {
        width: 100%;
        position: absolute;
        top: -320px;
        right: 0;
      }
      .right__tag {
        font-size: clamp(1em, 2vw, 1.5em);
        font-weight: 420;
        text-align: right;
        letter-spacing: -2%;
        color: $secondary;
      }
      .button__container {
        .lottie-container {
          &:hover {
            .icon {
              svg path {
                transform: translateY(60px);
                transition: transform 0.4s ease-out;
                @include media("<=phone") {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
