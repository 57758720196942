.projects__container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 64px;
  position: relative;
  z-index: 10;
  @include media("<=phone") {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
  .project__info {
    @include media("<=phone") {
      order: 1;
    }
    .project__title {
      font-size: clamp(1.5em, 8vw, 2.5em);
      color: $secondary;
      letter-spacing: -8%;
      font-weight: 500;
      margin-bottom: 1rem;
      @include media("<=phone") {
        margin-bottom: 0.5rem;
      }
    }
    .project__description {
      font-size: clamp(1em, 3vw, 1.4em);
      font-weight: 300;
      margin-bottom: 2rem;
      @include media("<=phone") {
        margin-bottom: 1.5rem;
    
      }
    }
    .tags__container {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 2rem;
      @include media("<=phone") {
        margin-bottom: 1.5rem;
      }
      span {
        font-size: 1.2rem;
        font-weight: 500;
      }
      ul {
        display: flex;
        gap: 1rem;
        li {
          padding: 0.5rem 1rem;
          border-radius: 50px;
          background-color: $accent;
        }
      }
    }
    .projects__button {
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 6px 6px 6px 16px;
        border: 2px solid $secondary;
        border-radius: 50px;
        font-size: 1.2rem;
        font-weight: 520;
        letter-spacing: -2%;
        &:hover{
            svg{
                transform: rotate(44deg);
                transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
            }
        }
        @include media("<=phone") {
          gap: 1rem;
          font-size: 1rem;
        }
        svg {
          width: 44px;
          height: 44px;
          @include media("<=phone") {
            width: 40px;
            height: 40px;
          }
        }
      }
      .prototype__button {
        border-color: transparent;
        background: $secondary;
        color: $primary;
        svg {
          rect {
            fill: $primary;
          }
          path {
            fill: $secondary;
          }
        }
      }
    }
  }
  .projects__image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 500px;
      height: auto;
      border-radius: 8px;
      @include media("<=tablet") {
        width: 370px;
      }
    }
  }
}
