.contact-section {
  position: relative;
  .contact-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    z-index: 20;
    .contact-title {
      .title {
        display: block;
        font-size: clamp(2.8em, 8vw, 8em);
        font-weight: 600;
        letter-spacing: -0.08em;
        color: $black;
      }
      .tag {
        font-size: clamp(2em, 4.5vw, 4.5em);
        font-weight: 320;
        letter-spacing: 0.02em;
        color: $secondary;
      }
    }
    .social-container {
      width: 100%;
      display: flex;
      gap: 2rem;
      @include media("<=tablet") {
        flex-direction: column;
        gap: 1rem;
      }
      ul {
        width: 50%;
        display: flex;
        justify-content: space-between;
        @include media("<=tablet") {
          width: 100%;
          justify-content: space-between;
        }
        .social-items {
          border: 2px solid transparent;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: $primary;
            border-color: $black;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          @include media("<=tablet") {
            width: 160px;
            height: 160px;
          }
          @include media("<=phone") {
            width: 120px;
            height: 120px;
          }
          @include media("<=400px") {
            width: 100px;
            height: 100px;
          }
          .social-links {
            width: 100%;
            height: 100%;
            position: relative;
            svg {
              width: 280px;
              height: 280px;
              position: relative;
              z-index: 1;
              @include media("<=desktop") {
                width: 180px;
                height: 180px;
              }
              @include media("<=tablet") {
                width: 160px;
                height: 160px;
              }
              @include media("<=phone") {
                width: 120px;
                height: 120px;
              }
              @include media("<=400px") {
                width: 100px;
                height: 100px;
              }
            }
            &:hover {
              svg {
                transform: translateY(80%);
                transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }
          }
        }
      }
    }
    .contact-arrows {
      position: absolute;
      top: 240px;
      right: 0;
      @include media("<=desktop") {
        top: 184px;
      }
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateY(-60px);
                @include media("<=phone") {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 132px;
          @include media("<=tablet") {
            top: 350px;
          }
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          right: 230px;
          @include media("<=tablet") {
            right: 120px;
          }
        }
      }
    }
  }
}
