.projects__section {
  .project__container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    gap: 2rem;
    @include media("<=phone") {
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
    }
    .info {
      width: 50%;
      .project__title {
        font-size: 4rem;
        letter-spacing: -8%;
        color: $secondary;
        font-weight: 580;
      }
      .projects__description {
        margin-top: 2rem;
        font-size: 1.2rem;
        font-weight: 400;
      }
      @include media("<=phone") {
        width: 100%;
      }
    }
    .buttons__container {
      position: absolute;
      top: 180px;
      right: 0;
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateY(-60px);
                @include media("<=phone") {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
    .gallery {
      img {
        width: 320px;
        height: 200px;
        margin-right: 1rem;
      }
    }
    .gallery__slider {
      position: relative;
      z-index: 10;
      overflow: hidden;
      img {
        margin-right: 1rem;
        width: 320px;
        height: 200px;
        border-radius: 8px;
      }
    }
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          position: absolute;
          top: 110px;
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          position: absolute;
          right: 120px;
        }
      }
    }
  }
}
.projects {
  position: relative;
}
.grid__component {
  background-image: linear-gradient(
      to right,
      $accent 1.25px,
      transparent 1.25px
    ),
    linear-gradient(to bottom, $accent 1.25px, transparent 1.25px);
  background-size: 64px 64px;
}
