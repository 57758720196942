.projects-container {
  position: relative;
  z-index: 10;
  padding: 64px;
  @include media("<=phone") {
    padding: 24px;
  }
  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }
  .project-card {
    overflow: hidden;
    cursor: pointer;
    &:hover {
      h3 {
        color: $secondary;
        letter-spacing: 2%;
        transition: 0.4s;
      }
    }
    .project-image {
      width: 520px;
      object-position: center center;
      transition: transform 0.3s ease;
      border-radius: 1rem;
      &:hover {
        transform: scale(1.05);
      }
      @include media("<=phone") {
        width: 360px;
      }
    }
    .project-content {
      h3 {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 480;
      }
    }
  }
}
