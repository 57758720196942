@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");

@import "./Variable.scss";
@import "./Breakpoints.scss";

// components
@import "./components/Header.scss";
@import "./components/Footer.scss";
@import "./components/HeaderFooter.scss";
@import "./components/HeaderGallery.scss";
@import "./components/LottieButton.scss";
@import "./components/FeaturedWork.scss";
@import "./components/FeaturedGallery.scss";
@import "./components/CompareImages.scss";
@import "./components/Service.scss";
@import "./components/Socials.scss";
@import "./components/OverlayNav.scss";
@import "./components/ProjectsContainer.scss";

// pages
@import "./pages/Home.scss";
@import "./pages/About.scss";
@import "./pages/Projects.scss";
@import "./pages/DumpYard.scss";
@import "./pages/CaseStudy.scss";
@import "./pages/ProjectsDetail.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: $secondary $black;
  background-color: $primary;
  color: $black;
  user-select: none;
}

a {
  text-decoration: none;
  color: $black;
}

ul {
  list-style: none;
}

button {
  border: none;
  outline: none;
  background: none;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.lottie {
  display: none;
}

.section {
  width: 100vw;
  height: 100vh;
  padding: 64px;
  @include media("<=desktop") {
    padding: 40px;
  }
  @include media("<=tablet") {
    padding: 32px;
  }
  @include media("<=phone") {
    padding: 24px;
  }
  .grids__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .row {
      display: flex;
      .col {
        width: 64px;
        height: 64px;
        border: 1px solid $accent;
      }
    }
  }
}

.grid__component {
  background-image: linear-gradient(
      to right,
      $accent 1.25px,
      transparent 1.25px
    ),
    linear-gradient(to bottom, $accent 1px, transparent 1px);
  background-size: 64px 64px;
}

.lines-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .horizontal-container {
    .horizontal-line {
      position: absolute;
    }
  }
  .vertical-container {
    .vertical-line {
      position: absolute;
    }
  }
}

input {
  outline: none;
  border: none;
  background: none;
}
