.home__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  .home__container {
    position: relative;
    z-index: 10;
    .name {
      .home__title {
        font-size: clamp(3.8em, 12vw, 18.5em);
        font-weight: 720;
        letter-spacing: -8%;
        line-height: 80%;
      }
    }
    .home__subtitle {
      font-size: clamp(1.5em, 4vw, 5.25em);
      font-weight: 320;
      letter-spacing: 2%;
      color: $secondary;
    }
  }
  .recruiter-button {
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 100;
    background: linear-gradient(to right, #ff5a0e, $secondary);
    color: $primary;
    padding: 10px 20px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    background: $primary;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    position: relative;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .close-button {
    font-size: 64px;
    font-weight: 300;
    cursor: pointer;
  }

  .video-container {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: $black;
    border-radius: 8px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
    }
  }

  .download-container {
    text-align: center;
    margin-top: 2rem;
  }

  .download-button {
    background:linear-gradient(to right, #ff7037, $secondary);
    color: $primary;
    padding: 10px 20px;
    border-radius: 50px;
    transition: background 0.3s ease;
    &:hover {
      background-color: rgba(255, 75, 47, 0.9);
    }
  }

  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 240px;
          @include media("<=desktop") {
            top: 110px;
          }
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          right: 220px;
          @include media("<=desktop") {
            right: 110px;
          }
        }
      }
    }
  }
}
