.about__section {
  width: 100vw;
  height: 100vh;
  .grids__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .row {
      display: flex;
      .col {
        width: 64px;
        height: 64px;
        border: 1px solid $accent;
      }
    }
  }
  .about__container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;
    border-bottom: 2px solid $black;
    .button {
      position: absolute;
      top: 30%;
      right: 32px;
      @include media("<=phone") {
        display: none;
      }
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateY(60px);
                transition: transform 0.4s ease-out;
              }
            }
          }
        }
      }
    }
    @include media("<=phone") {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .left {
      width: 50%;
      height: 50%;
      display: flex;
      flex-direction: column;
      @include media("<=phone") {
        width: 100%;
      }
      .top {
        width: 100%;
        height: 50%;
        display: flex;
        .gif {
          background-color: $secondary;
          padding: 2rem 3rem;
          @include media("<=phone") {
            padding: 1rem;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 2px solid $black;
        }
        .quote {
          padding: 2rem;
          @include media("<=phone") {
            padding: 1rem;
          }
          border-top: 2px solid $black;
          border-left: 2px solid $black;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            color: $black;
            font-size: clamp(1.2em, 2vw, 2em);
            font-weight: 500;
            letter-spacing: 0.02em;
            text-align: center;
          }
        }
      }
      .bottom {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid $black;
        span {
          color: $secondary;
          font-size: clamp(3em, 6vw, 6em);
          font-weight: 600;
          letter-spacing: -0.08em;
        }
      }
    }
    .right {
      width: 50%;
      height: 50%;
      @include media("<=desktop") {
        padding: 1rem;
        gap: 1rem;
      }
      @include media("<=phone") {
        width: 100%;
        height: auto;
        padding: 1rem;
        gap: 0.5rem;
      }
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      background-color: $secondary;
      padding: 2rem;
      color: $primary;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: clamp(2.2em, 3.8vw, 3.8em);
        font-weight: 600;
        letter-spacing: -0.08em;
      }
      .tag {
        font-size: clamp(1.2em, 2vw, 2em);
        font-weight: 320;
        letter-spacing: 0.02em;
      }
      .quote {
        color: $black;
        font-size: clamp(1.2em, 1.5vw, 1.5em);
        letter-spacing: 0.02em;
        font-weight: 500;
      }
    }
  }
}
.about__image {
  width: 100vw;
  height: 100vh;
  position: relative;
  .grids__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .row {
      display: flex;
      .col {
        width: 64px;
        height: 64px;
        border: 1px solid $accent;
      }
    }
  }
  .about__info {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    border-bottom: 2px solid $black;
    @include media("<=tablet") {
      flex-direction: column;
    }
    .left-container {
      padding: 24px;
      width: 30%;
      height: 100%;
      border-right: 2px solid $black;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 4rem;
      .about__tag {
        order: 1;
        font-size: 2rem;
        font-weight: 520;
        color: $secondary;
        letter-spacing: -8%;
      }
      @include media("<=tablet") {
        width: 100%;
        border-right: none;
        border-bottom: 2px solid $black;
        .about__buttons {
          display: none;
        }
      }
    }
    .right-container {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include media("<=tablet") {
        width: 100%;
      }
      .top {
        padding: 2rem;
        width: 100%;
        height: 50%;
        .title {
          font-size: clamp(2em, 4vw, 3.5em);
          color: $secondary;
          letter-spacing: -8%;
          margin-bottom: 1rem;
        }
        .description {
          font-size: clamp(1em, 4vw, 1.3em);
          letter-spacing: 2%;
          font-weight: 320;
          span {
            font-weight: 400;
            color: $secondary;
          }
        }
        @include media("<=tablet") {
        }
      }
      .bottom {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: flex-end;
        border-top: 2px solid $black;
        @include media("<=tablet") {
          height: 100%;
          flex-direction: column;
          align-items: center;
        }
        .experience {
          order: 1;
          width: 60%;
          height: 100%;
          padding: 1.5rem;
          border-left: 2px solid $black;
          @include media("<=tablet") {
            border: none;
            padding: 1rem;
            width: 100%;
          }
          h2 {
            font-size: clamp(1.4em, 4vw, 2em);
            color: $secondary;
            letter-spacing: -8%;
            margin-bottom: 0.8rem;
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            li {
              font-size: clamp(1em, 4vw, 1.2em);
            }
          }
        }
        .profile-image {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media("<=tablet") {
            border-bottom: 2px solid $black;
            width: 100%;
          }
          img {
            width: 420px;
            height: 420px;
            @include media("<=desktop") {
              width: 240px;
              height: 240px;
            }
            @include media("<=tablet") {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }
  }
}
.work__container {
  width: 100vw;
  height: 100vh;
  position: relative;
  .grids__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .row {
      display: flex;
      .col {
        width: 64px;
        height: 64px;
        border: 1px solid $accent;
      }
    }
  }
  .handshake {
    width: 100%;
    height: 60%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $black;
    @include media("<=tablet") {
      height: 40%;
    }
    img {
      width: 520px;
      @include media("<=phone") {
        width: 380px;
      }
    }
  }
  .work {
    width: 100%;
    height: 40%;
    display: flex;
    position: relative;
    z-index: 10;
    border-bottom: 2px solid $black;
    @include media("<=phone") {
      height: 100%;
      flex-direction: column;
      border-bottom: none;
    }
    .work-info {
      width: 50%;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-right: 2px solid $black;
      @include media("<=phone") {
        border-right: none;
        border-bottom: 2px solid $black;
        width: 100%;
        height: 30%;
      }
      span {
        font-size: clamp(2em, 4vw, 2.8em);
        font-weight: 600;
        letter-spacing: -8%;
        b {
          font-weight: 720;
          color: $secondary;
        }
      }
      .idea {
        font-size: clamp(1.5em, 4vw, 2em);
        font-weight: 400;
        letter-spacing: 2%;
      }
    }
    .handshake__button {
      width: 30%;
      height: 100%;
      display: flex;
      background-color: $black;
      transition: all 0.3s ease-in-out;
      @include media("<=phone") {
        width: 100%;
        height: 30%;
      }
      &:hover {
        opacity: 95%;
        cursor: pointer;
        a {
          color: $primary;
        }
      }
      a {
        height: 100%;
        width: 100%;
        padding: 2rem;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -8%;
        color: $primary;
        text-align: right;
      }
    }
    .work__buttons {
      width: 20%;
      height: 100%;
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 2px solid $black;
      @include media("<=phone") {
        width: 100%;
        height: 20%;
        flex-direction: row;
        justify-content: flex-end;
        display: none;
      }
    }
  }
}
.empty__container {
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 32px;
  .grids__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .row {
      display: flex;
      .col {
        width: 64px;
        height: 64px;
        border: 1px solid $accent;
      }
    }
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 260px;
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          left: 260px;
        }
      }
    }
  }
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .empty__buttons {
    position: absolute;
    z-index: 10;
    right: 2rem;
    bottom: 40%;
    @include media("<=phone") {
      bottom: 30%;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    z-index: 10;
    p {
      font-size: 3rem;
      color: $secondary;
      font-weight: 500;
      letter-spacing: -8%;
    }
    span {
      width: 50%;
      font-weight: 320;
      text-align: right;
      font-size: 1rem;
      @include media("<=phone") {
        width: 100%;
      }
    }
    .tag {
      color: $secondary;
    }
  }
}
