.lottie-container {
  width: clamp(3.5em, 5vw, 5em);
  height: clamp(3.5em, 5vw, 5em);
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  // Desktop hover styles
  &:hover {
    background-color: $accent;
    .icon {
      &:hover {
        svg path {
          transform: translateX(60px);
          transition: transform 0.4s ease-out;
        }
      }
    }
    .lottie {
      display: block;
    }
  }

  // Mobile overrides
  @include media("<=phone") {
    // Disable any hover-based animation on mobile
    &:hover {
      background-color: inherit; // or you can remove/change this if needed
      .icon {
        &:hover {
          svg path {
            transform: none;
            transition: none;
          }
        }
      }
      // Always hide the lottie element on mobile
      .lottie {
        display: none;
      }
    }
  }

  .icon {
    position: absolute;
    z-index: 1;
    svg {
      width: clamp(3.5em, 5vw, 5em);
      height: clamp(3.5em, 5vw, 5em);
    }
  }
}
