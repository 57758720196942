.dump__section {
  .dump__yard {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 20;
    .dump__title {
      .title {
        font-size: clamp(3.5em, 12vw, 12em);
        font-weight: 600;
        letter-spacing: -8%;
        color: $black;
      }
      .tagline {
        font-size: clamp(1.5em, 3vw, 3em);
        font-weight: 320;
        color: $secondary;
        letter-spacing: 2%;
      }
    }
    .button__container {
      @include media("<=phone") {
        position: absolute;
        bottom: 30%;
        right: 0;
      }
    }
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 120px;
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          right: 140px;
        }
      }
    }
  }
}
.soon {
  position: relative;
  .soon__container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    span {
      position: absolute;
      top: 25%;
      left: 1%;
      font-size: 1.2rem;
      a,
      b {
        color: $secondary;
        font-weight: 400;
      }
      a {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .visibility {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .visibility__container {
        p {
          font-size: clamp(1.3em, 5vw, 5em);
          font-weight: 600;
          color: $secondary;
          letter-spacing: -0.08em;
          margin-bottom: 0.5rem;
          a {
            color: $black;
          }
        }
      }
      .soon__arrow {
        @include media("<=tablet") {
          position: absolute;
          right: 0;
          bottom: 200px;
        }
        @include media("<=phone") {
          bottom: 120px;
        }
      }
    }
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          top: 60%;
        }
        &:nth-child(2) {
          top: 20%;
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          right: 240px;
          @include media("<=phone") {
            right: 100px;
          }
        }
      }
    }
  }
}
