.compare-container {
  width: 80%;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  user-select: none;
  @include media("<=tablet") {
    aspect-ratio: 9 /16;
  }
  .compare-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .compare-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
    @include media("<=tablet") {
      transition: none;
    }
  }
  .compare-handle {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    pointer-events: none;
    .compare-handle-bar {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 4px;
      height: 40px;
      background-color: $secondary;
      border-radius: 2px;
    }
  }
}
