.gallery__container {
  width: 100%;
  overflow: hidden;
  .scroller {
    display: flex;
    align-items: center;
    animation: scroll-left 6s linear infinite;
    img {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      margin-right: 1rem;
    }
    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
