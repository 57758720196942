.footer-section {
  background-color: $secondary;
  position: relative;
  .footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 10;
    .footer-content {
      position: relative;
      display: inline-block;
      font-size: clamp(4em, 18vw, 18em);
      font-weight: 800;
      letter-spacing: -8%;
      color: transparent;
      -webkit-text-stroke: 2px $black;
      overflow: hidden;
      &:hover {
        letter-spacing: 0.02em;
        transition: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &::before {
        content: "@UXKEO_";
        -webkit-text-stroke: transparent;
        position: absolute;
        top: 0;
        left: 0;
        color: $primary;
        width: 20rem;
        height: 20rem;
        z-index: 1;
        clip-path: circle(0% at 0 0);
        transition: clip-path 0.48s ease-out;
      }
      &:hover::before {
        clip-path: circle(50% at var(--x) var(--y));
      }
    }

    .footer-copy {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 1.5rem;
      font-weight: 300;
      a {
        color: $black;
        font-weight: 400;
        &:hover {
          letter-spacing: 0.02em;
          transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
      div {
        @include media("<=phone") {
          display: none;
        }
      }
    }
    .footer-arrow {
      position: absolute;
      right: 0;
      bottom: 10%;
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateY(-60px);
                @include media("<=phone") {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .grids__container {
    .row {
      .col {
        opacity: 50%;
      }
    }
  }
}
