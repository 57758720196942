.featured__work {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 36px;
  @include media("<=phone") {
    gap: 24px;
  }
  .work__container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    .work__title {
      font-size: clamp(2em, 4vw, 6em);
      font-weight: 580;
      letter-spacing: -8%;
      a{
        display: block;
        font-size: 1.2rem;
        color: $secondary;
        &:hover{
          letter-spacing: 2%;
          transition: 0.4s;
        }
      }
    }
    .featured__button {
      display: flex;
      align-items: center;
      .pagination {
        width: clamp(3.5em, 5vw, 5em);
        height: clamp(3.5em, 5vw, 5em);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $secondary;
        border-radius: 50%;
        span {
          color: $secondary;
          font-size: 1.2rem;
          letter-spacing: -8%;
        }
      }
    }
  }
}
