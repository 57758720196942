.casestudy__notfound {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 2rem;
  p {
    position: relative;
    z-index: 10;
    font-size: clamp(3.8em, 12vw, 12.5em);
    letter-spacing: -8%;
    line-height: 80%;
    font-weight: 600;
    text-align: right;
  }
  a {
    position: relative;
    z-index: 10;
    font-size: 3rem;
    color: $secondary;
  }
  .lines-container {
    .horizontal-container {
      .horizontal-line {
        &:nth-child(1) {
          position: absolute;
          top: 140px;
        }
      }
    }
    .vertical-container {
      .vertical-line {
        &:nth-child(1) {
          position: absolute;
          left: 140px;
        }
      }
    }
  }
}

.casestudy__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .casestudy__info {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    gap: 2rem;
    @include media("<=tablet") {
      padding: 32px;
    }
    @include media("<=phone") {
      padding: 4rem 24px;
    }
    .info {
      display: flex;
      align-items: flex-end;
      gap: 8rem;
      @include media("<=tablet") {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
      .title {
        position: relative;
        z-index: 10;
        font-size: clamp(2.8em, 8vw, 6em);
        text-align: right;
        letter-spacing: -8%;
        line-height: 90%;
        color: $secondary;
        font-weight: 520;
      }
      .description {
        position: relative;
        z-index: 10;
        font-size: clamp(1.2em, 4vw, 2em);
        font-weight: 440;
        letter-spacing: 2%;
      }
    }
    .back__button {
      position: absolute;
      top: 60%;
      z-index: 10;
      .lottie-container {
        &:hover {
          .icon {
            &:hover {
              svg path {
                transform: translateX(-60px);
              }
            }
          }
        }
      }
    }
    .lines-container {
      .horizontal-container {
        .horizontal-line {
          &:nth-child(1) {
            position: absolute;
            top: 232px;
          }
        }
      }
      .vertical-container {
        .vertical-line {
          &:nth-child(1) {
            position: absolute;
            left: 230px;
            @include media("<=tablet") {
              left: 100px;
            }
          }
        }
      }
    }
  }
  .image__info {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .grids__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      .row {
        display: flex;
        .col {
          width: 64px;
          height: 64px;
          border: 1px solid $accent;
        }
      }
    }
  }

  .screens__container {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .prototype__frame {
    padding: 24px;
    width: 100vw;
    height: 100vh;
    position: relative;
    iframe {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      border: 2px solid $secondary;
      border-radius: 1rem;
    }
    .grids__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      .row {
        display: flex;
        .col {
          width: 64px;
          height: 64px;
          border: 1px solid $accent;
        }
      }
    }
  }
  .compare-container {
    position: relative;
    z-index: 10;
  }
}
